<template>
  <!-- Footer Start -->
  <div class="footer-section dark-mode-texts">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xs-6 col-lg-3 col-md-3 col-sm-6 col-6">
          <div class="single-footer mb-13 mb-lg-9">
            <h3 class="footer-title mb-7">Company</h3>
            <ul class="footer-list list-unstyled gr-text-9">
              <li class="py-2">
                <router-link to="/" class="gr-text-color">Home</router-link>
              </li>
              <li class="py-2">
                <router-link to="/about" class="gr-text-color"
                  >About Us</router-link
                >
              </li>
              <li class="py-2" v-if="this.checkAffiliateUser == 1">
                <router-link to="/affiliate/profile/" class="gr-text-color"
                  >Affiliate Program</router-link
                >
              </li>
              <li class="py-2" v-if="this.checkAffiliateUser == 0">
                <router-link to="/affiliate" class="gr-text-color"
                  >Affiliate Program</router-link
                >
              </li>
              <li class="py-2">
                <router-link to="/terms-and-conditions" class="gr-text-color"
                  >Terms and Conditions</router-link
                >
              </li>
              <li class="py-2">
                <router-link to="/privacy-policy" class="gr-text-color"
                  >Privacy Policy</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xs-6 col-lg-3 col-md-3 col-sm-6 col-6">
          <div class="single-footer mb-13 mb-lg-9">
            <h3 class="footer-title mb-7">Explore</h3>
            <ul class="footer-list list-unstyled gr-text-9">
              <li class="py-2">
                <router-link to="/contact" class="gr-text-color"
                  >Contact Us</router-link
                >
              </li>
              <li class="py-2">
                <router-link to="/faqs" class="gr-text-color"
                  >FAQ's</router-link
                >
              </li>
              <li class="py-2">
                <router-link :to="{name: 'front-blogs'}" class="gr-text-color"
                  >Blogs</router-link
                >
              </li>
			<li class="py-2">
               <router-link to="/business-partner" class="gr-text-color"
                  >Become a Partner</router-link
                >
              </li> 
			<li class="py-2">
                <router-link to="/business-directory" class="gr-text-color"
                  >Business Directory</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xs-6 col-lg-3 col-md-3 col-sm-6 col-6">
          <div class="single-footer mb-13 mb-lg-9">
            <h3 class="footer-title mb-7">Address</h3>
			<div v-html="footer_address.footerAddress"></div>
			
          </div>
        </div>
        <div class="col-xs-6 col-lg-3 col-md-3 col-sm-6 col-6">
          <div class="single-footer mb-13 mb-lg-9 app_links">
            <h3 class="footer-title mb-7">Connect with Us</h3>
            <ul class="footer-list list-unstyled gr-text-9">
              <li class="py-2">
                <a
                  v-bind:href="'' + links.android + ''"
                  class="gr-text-color google_play" 
                  target="_blank" rel="noreferrer"
                ></a>
              </li>
              <li class="py-2">
                <a
                  v-bind:href="'' + links.ios + ''"
                  class="gr-text-color apple_store" 
                  target="_blank" rel="noreferrer"
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="row copyright">
        <div class="col-lg-8 col-md-6">
          <ul class="bottom_footer_menu">
            <li>Let's Intro Copyright ©️ 2024</li>
            <!--<li><a href="#">Help</a></li>
            <li><a href="#">Privacy</a></li>
            <li><a href="#">Terms</a></li>-->
          </ul>
        </div>
        <div class="col-lg-4 col-md-6">
          <ul class="footer_social">
            <li>
              <a class="facebookBtn smGlobalBtn" v-bind:href="'' + sociallinks.facebook + ''" 
              target="_blank" rel="noreferrer"
                ><font-awesome-icon icon="fa-brands fa-facebook" /></a>
            </li>
            <li>
              <a class="tumblrBtn smGlobalBtn" v-bind:href="'' + sociallinks.twitter + ''" 
              target="_blank" rel="noreferrer"
                ><font-awesome-icon icon="fa-brands fa-twitter" /></a>
            </li>
            <li>
              <a class="rssBtn smGlobalBtn" v-bind:href="'' + sociallinks.instagram + ''" 
              target="_blank" rel="noreferrer"
                ><font-awesome-icon icon="fa-brands fa-instagram" /></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- Footer End -->
</template>

<script>
/*
import '@/assets/front/js/vendor.min.js';

import '@/assets/front/plugins/fancybox/jquery.fancybox.min.js';

import '.@/assets/front/plugins/aos/aos.min.js';

import '@/assets/front/plugins/slick/slick.min.js';

import '@/assets/front/plugins/date-picker/js/gijgo.min.js';

import '@/assets/front/plugins/counter-up/jquery.waypoints.min.js';

import '@/assets/front/counter-up/jquery.counterup.min.js';


*/

import "@/assets/front/plugins/nice-select/jquery.nice-select.min.js";

import "@/assets/front/js/custom.js";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faFacebook, faGoogle, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';

library.add(faFacebook, faGoogle, faTwitter, faInstagram)

import { db } from "@/main";

export default {
  components: {FontAwesomeIcon},
  data() {
    return {
      links: {},
      sociallinks: {},
      users: {},
      checkAffiliateUser: '',
 currentyear:new Date().getFullYear(),
      footer_address: {},
    };
  },

  created: function() {
    this.fetchFeatures();
    this.getUser();
    this.get_footer_address();
  },

  methods: {
    getUser() {
      var id = localStorage.getItem("userSessionId");
      if(id){
        db.collection("users")
          .doc(id)
          .get()
          .then((doc) => {
            this.users = doc.data();
            if (!doc.data().affiliateStatus || doc.data().affiliateStatus == 0) {
              this.checkAffiliateUser = 0;
            } else {
              this.checkAffiliateUser = 1;
            }
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
      }
    },

	get_footer_address() {
	db.collection("address").doc('UukY8WLJp0Bhn12B4mJB')
      .get()
      .then((doc) => {
              // console.log('hhhh55');
              // console.log(doc.id, " => ", doc.data());
              this.footer_address = doc.data();
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
	},

    fetchFeatures() {
      db.collection("links")
        .doc("RH0Mssvb2kzH1nn22v3I")
        .get()
        .then((doc) => {
          //console.log('hhhh44');
          //console.log(doc.id, " => ", doc.data());
          this.links = doc.data();
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });

      db.collection("sociallinks")
        .doc("ot3idgvr902v0m6ynLEz")
        .get()
        .then((doc) => {
          //console.log('hhhh55');
          //console.log(doc.id, " => ", doc.data());
          this.sociallinks = doc.data();
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
  },
};
</script>
<style>
#social {
  margin: 20px 10px;
  text-align: center;
}

.smGlobalBtn { /* global button class */
    display: inline-block;
    position: relative;
    cursor: pointer;
    width: 50px;
    height: 50px;
    border:2px solid #ddd; /* add border to the buttons */
    padding: 0px;
    text-decoration: none;
    text-align: center;
    color: #fff;
    font-size: 25px;
    font-weight: normal;
    line-height: 2em;
    border-radius: 27px;
    -moz-border-radius:27px;
    -webkit-border-radius:27px;
        margin-left: 10px;
}

/* facebook button class*/
.facebookBtn{
    background: #4060A5;
}

.facebookBtn:hover{
    color: #4060A5;
    background: #fff;
    border-color: #4060A5; /* change the border color on mouse hover */
}

/* twitter button class*/
.twitterBtn{
    background: #00ABE3;
}

.twitterBtn:hover{
      color: #00ABE3;
      background: #fff;
      border-color: #00ABE3;
}

/* google plus button class*/
.googleplusBtn{
    background: #e64522;
}

.googleplusBtn:hover{
      color: #e64522;
      background: #fff;
      border-color: #e64522;
}

/* linkedin button class*/
.linkedinBtn{
    background: #0094BC;
}

.linkedinBtn:hover{
      color: #0094BC;
      background: #fff;
      border-color: #0094BC;
}

/* pinterest button class*/
.pinterestBtn{
    background: #cb2027;
}

.pinterestBtn:hover{
      color: #cb2027;
      background: #fff;
      border-color: #cb2027;
}

/* tumblr button class*/
.tumblrBtn{
    background: #1DA1F2;
}

.tumblrBtn:hover{
      color: #3a5876;
      background: #fff;
      border-color: #3a5876;
}

/* rss button class*/
.rssBtn{
     background: #f09433; 
background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}

.rssBtn:hover{
      color: #cc2366;
      background: #fff;
      border-color: #cc2366;
}
.footer_social li a {
    color: #fff;
    padding-left: 0;
}
@media (max-width:767px){
.footer_social li a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}
}
</style>
