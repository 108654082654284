<template>
<!-- Main Start -->
    <div class="home__page__new__wrapper">
      <section class="common__section" v-for="m in features1" :key="m.id">
        <div class="container">
          <div class="row">
            <div class="col-lg-5">
              <div class="image__wrapper">
                <img :src="m.image" class="img-fluid" alt="section-image" />
              </div>
            </div>
            <div class="col-lg-6">
              <h4 class="section__title">
                {{m.title}}
              </h4>
              <p class="section__discription">
                {{m.description}}
              </p>
              <router-link v-if="loggedIn == false" to="/registration" class="cta_link">{{m.btext}}</router-link>
              <!-- <a href="/registration" class="cta_link">{{m.btext}}</a> -->
            </div>
          </div>
        </div>
      </section>
      
    </div>
<!-- Main End -->
</template>
<script>
import { db } from "@/main";
    
export default {
  components:{
   
},
data(){
    return{
        features1: [],
        components:{},
        loggedIn: '',
    }
  },

  created: function()
  {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.fetchFeatures();
      this.loggedIn = localStorage.getItem("userSessionId")|| false;
  },

  methods: {

    fetchFeatures()
    {
           
      db.collection("inspired")
      .get()
      .then((querySnapshot) => {
        //console.log('hhhh');
        querySnapshot.forEach((doc) => {

          // console.log(doc.id, " => ", doc.data().image);
         // this.features1 = doc.data();
          //  console.log("debug debug");
              this.features1.push({
                id: doc.id,
                btext:doc.data().btext,
                title: doc.data().title,
                description:doc.data().short_description,
                image: doc.data().image,
                created: doc.data().created,
              }); 

         // });
          

        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

    }
  }
}
</script>
<style>
.home__page__new__wrapper .common__section {
        padding: 100px 0;
      }
      .home__page__new__wrapper .common__section .image__wrapper {
        position: relative;
      }

      .home__page__new__wrapper .common__section .image__wrapper:before {
        content: "";
        position: absolute;
        left: -8%;
        top: 10%;
        width: 100%;
        height: 100%;
        background: #03e1bc;
        z-index: 0;
      }

      .home__page__new__wrapper .common__section .image__wrapper img {
        position: relative;
        z-index: 5;
        width: 100%;
        object-fit: cover;
        height: 100%;
        max-height: 330px;
        min-height: 330px;
      }
      .home__page__new__wrapper
        .common__section:nth-child(even)
        .image__wrapper:before {
        left: auto;
        right: -8%;
      }
      .home__page__new__wrapper .common__section .section__title {
        font-size: 2.5rem;
      }

      .home__page__new__wrapper .common__section .section__discription {
        font-size: 20px;
        max-width: 60%;
        margin-top: 30px;
        margin-bottom: 30px;
        color: #5a5858;
      }

      .home__page__new__wrapper .common__section .cta_link {
        background: #03e1bc;
        padding: 12px 30px;
        text-decoration: none;
        color: #fff;
        border-radius: 5px;
        font-size: 20px;
        text-transform: uppercase;
      }

      .home__page__new__wrapper .common__section .cta_link:hover {
        background: #f88300;
      }
      .home__page__new__wrapper .common__section:nth-child(even) {
        background: #f3f3f3cc;
      }

      .home__page__new__wrapper .common__section .row {
        justify-content: space-between;
      }
      .home__page__new__wrapper .common__section:nth-child(even) .row {
        flex-direction: row-reverse;
      }

      /* media quaries */
      @media (max-width: 1440px) {
        .home__page__new__wrapper .common__section .section__discription {
          max-width: 80%;
        }
      }

      @media (max-width: 1280px) {
        .home__page__new__wrapper .common__section {
          padding: 100px 5%;
        }
      }
      @media (max-width: 768px) {
        .home__page__new__wrapper .common__section .image__wrapper:before {
          left: -3%;
          top: 7%;
        }
        .home__page__new__wrapper
          .common__section:nth-child(even)
          .image__wrapper:before {
          left: auto;
          right: -3%;
        }
        .home__page__new__wrapper .common__section .image__wrapper {
          margin-bottom: 80px;
        }
      }

      @media (max-width: 767px) {
        .home__page__new__wrapper .common__section .image__wrapper img {
          max-height: 270px;
          min-height: 270px;
        }
        .home__page__new__wrapper .common__section .section__title {
          font-size: 25px;
        }
        .home__page__new__wrapper .common__section .image__wrapper {
          margin-bottom: 50px;
        }
        .home__page__new__wrapper .common__section .section__discription {
          max-width: 100%;
          font-size: 15px;
          margin-top: 10px;
        }
        .home__page__new__wrapper .common__section {
          padding: 70px 5%;
        }
      }
</style>

