<template>

    <body>

        <!-- preloader -->
        <Loader> </Loader>
        <!-- preloader -->
        <div class="frame">
            <div class="site-wrapper overflow-hidden">

                <!-- Header Section -->
                <Affiliate-Header> </Affiliate-Header>

                <!-- manges sales section html code  -->
                <div class="page_main_div">
                    <div class="container">
                        <div class="row pb-4">
                            <div class="col-12 col-md-6">
                                <div class="revenusTitle">
                                    <h1 class="manage-payment">Manage Payments</h1>
                                    <!-- <h1>Revenus</h1>
                        <h1 class="pricetotal">Rs 58,000/-</h1> -->
                                </div>
                            </div>
                            <div class="col-12 col-md-6 colComman">
                                <div class="rightTitle_top">
                                    <span class="title_one">
                                        <div>
                                        Completed Earning: <b class="boldTitle">${{this.tot.toFixed(2)}}</b>
                                        </div>
                                        <div>
                                        Upcoming Earnings: <b class="boldTitle">${{this.UpcomeingTot.toFixed(2)}}</b>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="linesolid">
                                <hr class="hori">
                            </div>
                        </div>
                        <!-- withrawals list html code -->
                        <div class="row mt-5">
                            <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                                <div class="letconetn_with">
                                    <div class="withrawalsTitile">Withdraw</div>
                                    <div class="righttitle">
                                        <!-- <button type="text" class="btn btn-white">
                                            <img src="../../assets/affiliate/payment/image/pay.png" alt="pay">
                                            <span class="btnText">PayPal Amount</span>
                                        </button> -->
                                        <button type="text" class="btn btn-white" @click="account">
                                            <img src="../../assets/affiliate/payment/image/bank.png" alt="pay">
                                            <span class="btnText">Bank Transfer</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- withrawals list html code -->
                        <!-- table payment  -->
                        <template>
                            <div title="List Affiliate Users" class="payment-search-div">
                                <!-- search input -->
                                <div class="custom-search d-flex justify-content-end">
                                    <b-form-group>
                                        <div class="d-flex align-items-center payment-search">
                                            <!-- <label class="mr-1">Search</label> -->
                                            <b-form-input v-model="searchTerm" placeholder="Search" type="text"
                                                class="d-inline-block" />
                                        </div>
                                    </b-form-group>
                                </div>

                                <!-- table -->
                                <vue-good-table :columns="columns" :rows="rows" :rtl="direction" :search-options="{
                                  enabled: true,
                                  externalQuery: searchTerm
                                }" :line-numbers="true" :pagination-options="{
                                  enabled: true,
                                  perPage: pageLength
                                }">
                                    <template slot="table-row" slot-scope="props">

                                        <!-- Column: Name -->
                                        <span v-if="props.column.field === 'image'" class="text-nowrap">
                                            <b-img :src="imgUrl(props.row.image)" class="mx-1" v-bind="mainProps">
                                            </b-img>
                                        </span>

                                        <!-- Column: STATUS -->
                                        <span v-else-if="props.column.field === 'status'" style="white-space: nowrap;">
                                            <span v-if="props.row.status == 1">
                                                <label class="rejected_label" variant="danger">Cancelled</label>
                                            </span>
                                            <span v-else-if="props.row.cool_down_days == 100">
                                                <label class="success_label" variant="success">Success</label>

                                            </span>
                                            <span v-else>
                                                <label class="warning_label" variant="danger">Panding</label>
                                            </span>
                                        </span>

                                        <!-- Column: Action -->
                                        <span v-else-if="props.column.field === 'action'">
                                            <!-- <div v-show="progressBar1" ref="progressBar1">
                                                <b-progress :value="props.row.value" :max="props.row.cool_down_days" show-progress animated class="progress-bar-1">
                                                </b-progress>
                                            </div> -->
                                            <span v-if="props.row.status == 1">
                                                <!-- <label class="approved_label" variant="success">Approved</label> -->
                                                <label class="rejected_label" variant="danger">Cancelled</label>
                                            </span>
                                            <span v-else>
                                                <div v-show="progressBar2" ref="progressBar2">

                                                    <b-progress :value="props.row.value" :max="props.row.cool_down_days"
                                                        show-progress animated class="progress-bar-2">
                                                    </b-progress>
                                                </div>
                                            </span>
                                        </span>

                                        <!-- Column: Common -->
                                        <span v-else>
                                            {{ props.formattedRow[props.column.field] }}
                                        </span>
                                    </template>

                                    <!-- pagination -->
                                    <template slot="pagination-bottom" slot-scope="props">
                                        <div class="d-flex justify-content-between flex-wrap">
                                            <div class="d-flex align-items-center mb-0 mt-1">
                                                <span class="text-nowrap ">
                                                    Showing 1 to
                                                </span>
                                                <b-form-select v-model="pageLength" :options="['5', '10', '25']"
                                                    class="mx-1"
                                                    @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                                <span class="text-nowrap"> of {{ props.total }} entries </span>
                                            </div>
                                            <div>
                                                <b-pagination :value="1" :total-rows="props.total"
                                                    :per-page="pageLength" first-number last-number align="right"
                                                    prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                                                    @input="(value) => props.pageChanged({ currentPage: value })">
                                                    <template #prev-text>
                                                        <feather-icon icon="ChevronLeftIcon" size="18" />
                                                    </template>
                                                    <template #next-text>
                                                        <feather-icon icon="ChevronRightIcon" size="18" />
                                                    </template>
                                                </b-pagination>
                                            </div>
                                        </div>
                                    </template>
                                </vue-good-table>
                            </div>
                        </template>
                        <!-- table payment  -->
                    </div>
                </div>
                <!-- manges sales section html code  -->

                <!-- Footer Section -->
                <Front-Footer> </Front-Footer>

            </div>
        </div>

    </body>
</template>

<script>
import VueMeta from 'vue-meta'
import Header from './Affiliate-Header';
import Footer from './Front-Footer';
import inspired from './common/Inspired';
import AffiliateSubscription from './common/Affiliate-Subscription';
import Loader from './Loader';
import Vue from 'vue'
import store from '@/store'
import axios from 'axios';
import moment from 'moment'

import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
    BAvatar, BBadge, BProgress, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton, BContainer, BRow, BCol, BImg
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
// import the styles
import 'vue-good-table/dist/vue-good-table.css'
import { PlusIcon } from 'vue-feather-icons'

import { db } from '@/main'


import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
Vue.use(VueMeta)


export default {
    components: {
        'Affiliate-Header': Header,
        'Front-Footer': Footer,
        'Affiliate-Subscription': AffiliateSubscription,
        'Inspired': inspired,
        'Loader': Loader,
        'VueSlickCarousel': VueSlickCarousel,
        BCardCode,
        VueGoodTable,
        BAvatar,
        BBadge,
        BProgress,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BButton,
        BContainer,
        BRow,
        BCol,
        BImg,
        PlusIcon,


    },
    data() {
        return {
            progressBar2: true,
            // progressBar1: false,
            value: '',
            max: '',
            components: {},
            pages: {},
            slickOptions: {
                //options can be used from the plugin documentation
                slidesToShow: 3,
                infinite: true,
                meta_title: '',
                meta_description: '',
            },
            subscriptionss: [],
            mainProps: { blank: false, width: 75, height: 75, class: 'm1' },
            pageLength: 5,
            dir: false,
            columns: [
                // {
                //     label: 'Affiliate Id',
                //     field: 'affiliate_id',
                // },
                {
                    label: 'User Id',
                    field: 'user_id',
                },
                {
                    label: 'Order Id',
                    field: 'order_id',
                },
                {
                    label: 'Product Id',
                    field: 'product_id',
                },
                {
                    label: 'Amount',
                    field: 'amount',
                },
                {
                    label: 'Dated',
                    field: 'created',
                },
                {
                    label: 'Clearing',
                    field: 'action',
                    sortable: false,
                },
                {
                    label: 'Status',
                    field: 'status',
                    sortable: false,
                },
            ],
            rows: [],
            searchTerm: '',
            status: [{
                1: 'Current',
                2: 'Professional',
                3: 'Rejected',
                4: 'Resigned',
                5: 'Applied',
            },
            {
                1: 'light-primary',
                2: 'light-success',
                3: 'light-danger',
                4: 'light-warning',
                5: 'light-info',
            }],
        }
    },
    metaInfo() {
        //alert(this.meta_title);
        return {
            title: localStorage.getItem("meta_title"),
            meta: [
                { name: 'description', content: localStorage.getItem("meta_description") },
            ]
        }
    },
    created: function () {
        this.paymentHistory();

    },

    methods: {
        account() {
            // alert('test');
            this.$router.push({ name: 'AffiliateAccount' });
        },

        paymentHistory() {
            var uid = localStorage.getItem("userSessionId");
            store.dispatch('app/commitActivateLoader');

            this.rows = [];
            var i = 1;
            var j = 0
            this.tot = 0;
            this.UpcomeingTot = 0;
            db.collection("affiliates")
                .where("affiliate_id", "==", uid)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((docs) => {
                        db.collection("affiliates_discount")
                            .where("product", "==", docs.data().product_id)
                            .get()
                            .then((querySnapshot) => {
                                querySnapshot.forEach((doc) => {
                                    let Enddate = moment(docs.data().created).add(doc.data().cool_down_days, 'days');
                                    let currentdate = moment();
                                    this.max = doc.data().cool_down_days;
                                    if (doc.data().discount_type == "percent") {
                                        var discount_amount = doc.data().discount;
                                        console.log("discount_amount", discount_amount)
                                        var Totalamount = (discount_amount / 100) * 99.5
                                        Totalamount = Totalamount.toFixed(2)
                                        console.log("calculate_amount", Totalamount)
                                    } else {
                                        var Totalamount = doc.data().discount;
                                    }
                                    this.RemainingDays = datediff(currentdate._d, Enddate._d);
                                    console.log("maximum-Day's", this.max)
                                    console.log("Remaining-Day's", this.RemainingDays);
                                    console.log("amount", docs.data().amount)
                                    this.value = this.max - (this.RemainingDays);

                                    this.rows.push({
                                        index: i,
                                        id: docs.id,
                                        // affiliate_id: docs.data().affiliate_id,
                                        user_id: docs.data().user_id,
                                        order_id: docs.data().order_id,
                                        product_id: docs.data().product_id,
                                        status: docs.data().delete_id,
                                        amount: Totalamount,
                                        cool_down_days: doc.data().cool_down_days,
                                        max: this.max,
                                        value: this.value,
                                        created: docs.data().created,
                                        modified: docs.data().modified,
                                    });
                                    console.log(doc.id, " => ", doc.data());
                                    i++;
                                    store.dispatch('app/commitDeactivateLoader');
                                    console.log(this.value);
                                    console.log(j)
                                    if (this.rows[j].value >= this.rows[j].cool_down_days && this.rows[j].status != "1") {
                                        console.log(this.rows[j])
                                        console.log(j)
                                        this.rows[j].value = 100;
                                        this.rows[j].cool_down_days = 100;
                                        this.tot += parseFloat(this.rows[j].amount);
                                        // this.$refs['progressBar1'].style.display.none
                                        // console.log(this.$refs['progressBar1']);
                                        localStorage.setItem("total_earning", this.tot);
                                    } else if (this.rows[j].value <= this.rows[j].cool_down_days && this.rows[j].status != "1") {
                                        console.log(this.rows[j])
                                        this.UpcomeingTot += parseFloat(this.rows[j].amount);
                                    }else {
                                        console.log("error!")
                                    }
                                    j++;
                                    // }
                                    // console.log(tot);
                                });
                                console.log(this.UpcomeingTot);
                            })
                            .catch((error) => {
                                console.log("Error getting documents: ", error);
                                store.dispatch('app/commitDeactivateLoader');
                            });
                    });
                    store.dispatch('app/commitDeactivateLoader');
                })

            function datediff(first, second) {
                // Take the difference between the dates and divide by milliseconds per day.
                // Round to nearest whole number to deal with DST.
                return Math.round((second - first) / (1000 * 60 * 60 * 24));
            }
        },
    }
}

</script>
<style>
/* afflialate */

@import "../../assets/affiliate/css/style.css";

@import "../../assets/affiliate/css/responsive.css";

.progress-bar-1>.progress-bar {
    background-color: #f88300 !important;
}

.progress-bar-2>.progress-bar {
    background-color: #03e1bc !important;
}

.btnText {
    white-space: nowrap;
}

.rejected_label {
    background-color: #e63233;
    border-color: #e42728;
    color: #fff;;
    border-radius: 17px;
    padding: 3px 3px 3px 3px;
    margin: 6px 6px 6px 6px;
}

.success_label {
    background-color: #449d44;
    border-color: #398439;
    color: #fff;;
    border-radius: 17px;
    padding: 6px 6px 6px 6px;
    margin: 5px 5px 5px 5px;
}

.warning_label {
    color: #fff;
    background-color: #f0ad4e;
    border-color: #eea236;
    border-radius: 17px;
    padding: 6px 6px 6px 6px;
    margin: 5px 5px 5px 5px;
}
.title_one{
    text-align: justify;
}
@media screen and (max-width: 576px) {
    .manage-payment{
        font-size: 15px;
    }
    .revenusTitle{
        margin-top: 70px;
    }
    .payment-search{
        margin-right: 145px;
    }
    .payment-search-div{
        margin-top: 30px;
    }
    .header-btn {
        background-color: unset !important;
    }
    .navbar-top.d-flex.justify-content-between {
        height: 60px;
    }
    .rightTitle_top {
        text-align: center;
        width: 100%;
        display: block;
    }
    .title_one {
        text-align: center;
    }
    h1.manage-payment {
        display: block;
        text-align: center;
        width: 100%;
    }
  }
</style>
